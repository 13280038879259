/* Show it is fixed to the top */
body {
	min-height: 75rem;
	padding-top: 4.5rem;
}

/* back to top button */
.back-to-top-btn {
    font-size: 2.2em;
    position: fixed;
    bottom: 23px;
    right: 23px;
    z-index: 40;
    width: 56px;
    height: 56px;
    color: #fff;
    background-color: #233445;
    border: 0;
    border-radius: 50%;
    text-align: center;
    display: none;
    outline: none
}
.back-to-top-btn.left {
    right: auto;
    left: 23px
}
.back-to-top-btn.right {
    right: 23px;
    left: auto
}
/* end back to top button */
